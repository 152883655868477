/****************************************************
*************** General Footer Styles ***************
****************************************************/
#footer {

    .footer-template {
        .fl-row-content-wrap {
            padding-bottom: 0;
            padding-top: 0;
        }
        .fl-module-map {
            .fl-module-content {
                margin: 0;
                filter: grayscale(1);
            }
        }
        .fl-col-bg-overlay {
            .fl-col-content {
                padding: 5em 0;
                .footer-cta-box {
                    background: #fff;
                    width: 70%;
                    max-width: 700px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    .fl-module-content {
                        padding: .5em 1em;
                        text-align: center;
                        .fl-rich-text {
                            p {
                                &:first-child {
                                    font-size: 2.8rem;
                                    color: $color-primary;
                                }
                                a {
                                    font-size: 2.8rem;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}




/****************************************************
******************** Footer Menu ********************
****************************************************/
.footer-menu {
    @extend .hidden-xs;
    @extend .hidden-is;

    nav {
        @extend .container;

        #footer-nav {
            align-items: flex-start;

            // Generic #footer-nav list-item styles
            li {
                flex: 0 1 auto;
                text-align: left;
                vertical-align: top;

                &.current-menu-item > a {
                    font-weight: bold;
                }
            }

            > li {
                // First Level Sub-Menus
                > .sub-menu {
                    .menu-item a {
                        font-size: $font-size-small;
                    }
                }
            }
        }
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-social-links {
    @extend .container;
    padding: $grid-gutter-width/2 0;
    text-align: center;

    h4 {
        margin: 0 0 $grid-gutter-width/2;
    }

    .social-links-list {
        @extend .nav;
        @extend .nav-center;
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-widgets {
    @extend .container;
}




/****************************************************
******** Copyright Line (Last Line of site) *********
****************************************************/
.copyright {
    padding-bottom: 2.5em;
    color: $gray-light;

    .cookies-not-accepted & {
        padding-bottom: 5em;

        @media screen and (min-width: $screen-sm-min){
            padding-bottom: 3em
        }
    }
}

.copyright-row {
    @extend .row;
    padding-top: $grid-gutter-width/2;
    font-size: $font-size-small;

    ul li {
        @extend .pipe-before;
        float: left;

        @media screen and (max-width: $screen-is-max){
            float: none;

            &::after,
            &::before {
                display: none;
            }
        }
    }

    // Unternehmensname, Copyright, Adresse etc.
    .copyright-info {
        @extend .nav;
        @include make-sm-column(7);
        padding-bottom: $grid-gutter-width/2;
        text-align: center;

        @media screen and (min-width: $screen-sm-min){
            text-align: left;
        }

        li {
            margin: 0;
            font-size: $font-size-small;
        }
    }

    // Copyright Navigation (Kontakt, Impressum, Datenschutz etc.)
    .copyright-navigation {
        @include make-sm-column(5);
        padding-bottom: $grid-gutter-width/2;

        .menu {
            @include list-unstyled;
            float: right;

            @media screen and (max-width: $screen-is-max){
                float: none;

                li {
                    float: none;
                    display: block;
                    text-align: center;
                }
            }

            a {
                color: $gray-light;
                &:hover{
                    color: $color-primary;
                }
            }
        }
    }

    // Website Flat Signature
    .website-flat-sign {
        text-align: center;
        margin: 1em auto 2em;
    }
}
