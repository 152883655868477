/****************************************************
************** General Stylesheet Rules *************
*****************************************************
* 4 Spaces Tab Indent
* large scope style blocks (e.g: organs, pages) are marked with 3 line star comments
* Smaller sub style blocks (e.g: molecules) are marked with a single line comment
* One line space between selectors inside nested selectors
* One line space between root level selectors
* Four lines space between logical style blocks
* Order style rules corresponding to the DOM order of the elements they're applied to
*
* Order for style properties:
* 1) display
* 2) position
* 3) box-model
* 4) color/font
* 5) others
*
* If not otherwise specified the properties are ordered in the following manner:
* from outside to inside of the element (margin, border, padding, width, height)
* from top to left (top, right, bottom, left)
* alphabetical
*/




/****************************************************
***************** Dev Support Styles ****************
****************************************************/
#header,
#hero,
#desktop-nav > div,
.fl-row-content,
.container,
main,
aside {
    // border: 1px solid $gray;
}




/****************************************************
************** Generic Element Styles ***************
****************************************************/
img {
    max-width: 100%;
    height: auto;

    // Prevent oversize bb-plugin lightbox portrait images
    &.mfp-img {
		max-height: 80vh !important;
	
        @media screen and (max-width: $screen-is-max) {
            margin-top: 2em;
            max-height: 65vh !important;
        }
    }
}

::selection {
    background: $color-primary;
    color:#FFF;
}

::-moz-selection {
    background: $color-primary;
    color:#FFF;
}

// Anchor element for scroll-top btn
#top {
    visibility: hidden;
    position: absolute;
    top: 0;
}

//Content Wrapper. Everything between header and footer
.content-wrap {
    margin: 0em auto 2em;

    .sidebar-primary.hero-area & {
        padding-top: 1em;
    }
}

.home {
    .content-wrap {
        margin: 1em auto 0;
    }
}




/****************************************************
*************** Global Content Styles ***************
****************************************************/
main {

    a {
        color:$color-primary;
        font-weight: 700;

        &:hover{
            color:lighten($color-primary, 10%)
        }
    }

    address {
        margin: 0;
    }

    blockquote {}

    figure {

        figcaption {}
    }

    hr {}

    p {}

    .read-more {
        white-space: nowrap;

        i {
            display: inline-block;
            margin-top: -.25em;
            padding-left: .25em;
            font-size: 1em;
            vertical-align: middle;
        }
    }
}

.sticky {}

a[class^="sc-"] , span[class^="sc-"] {
    display: inline-block;
    color: inherit;
}

span[class^="sc-label"], .sc-zip {
    margin-right: .25em;
}

.sc-email {
    margin-bottom: .5em;
}

ol, ul {
    margin: 0;
    padding: 0;

    &.styled-list {
        li {
            position: relative;
            padding: .5em 0;
            padding-left: 1.75em;
            list-style-position: inside;
            list-style: none;
            background-image: url(../images/list-icon.png);
            background-repeat: no-repeat;
            background-position: left .65em;
        }

    }
}


// Make absolute positioned modules editable. You have to set the class
.fl-builder-edit .absolute-fl-module .fl-module-content {
    top: 0 !important;
    margin-top: 0 !important;
}

// NO-js fallback for animated bb-plugin elements
.no-js {
    .fl-animation {
        opacity: 1 !important;
    }
}

// Fix width/height 1px on svg images in widgets
.widget {
    img {
        &.svg {
            width: 100%;
        }
    }
}

// style elements from beaver builder
.page-intro {
    h2 {
        .fl-heading-text {
            font-family: $font-family-sans-serif;
            color: $gray-dark;
        }
    }
}

.teaserbox {
    .fl-callout {
        border-bottom: 1px solid $color-primary;
        padding-bottom: 2em;
        margin: 2em 3em;
        @media screen and (min-width: $screen-sm) {
            margin: 0;
        }
        @media screen and (min-width: $screen-md) {
            margin: 0 1em;
        }
        .fl-callout-content {
            .fl-callout-photo {
                margin-bottom: 2em;
                .fl-photo-img {
                    border: 10px solid #fff;
                    border-radius: 100%;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
                }
            }
            .fl-callout-title {
                font-family: $font-family-serif;
                font-size: 3rem;
                .fl-callout-title-link {
                    font-weight: 400;
                }
            }
            .fl-callout-text-wrap {
                .fl-callout-text {
                    @media screen and (min-width: $screen-sm) {
                        min-height: 210px;
                    }
                    @media screen and (min-width: $screen-md) {
                        min-height: 150px;
                    }
                }
                .fl-callout-cta-link {
                    font-weight: 400;
                    &::after {
                        content: "\e81c";
                        font-family: "custom-icons";
                        padding-left: .5em;
                    }
                }
            }
        }
    }
}

.fl-module-blog-posts {
    border-top: 2px solid $color-primary;
    border-bottom: 2px solid $color-primary;
    .uabb-module-content {
        .slick-prev,
        .slick-next {
            i {
                background: transparent;
                font-size: 5rem;
                color: $color-primary;
            }
        }
        .uabb-post-wrapper {
            .uabb-blog-posts-shadow {
                .uabb-blog-post-inner-wrap {
                    .uabb-post-thumbnail,
                    .uabb-thumbnail-position-left {
                        width: 100%;
                        @media screen and (min-width: $screen-sm) {
                            width: 30%;
                        }
                    }
                    .uabb-blog-post-content {
                        width: 100%;
                        @media screen and (min-width: $screen-sm) {
                            width: 70%;
                            padding: 0 25px;
                        }
                        .uabb-post-heading {
                            font-weight: 700;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}

.fl-row-bg-overlay {
    .fl-module-rich-text {
        .fl-rich-text {
            p {
                color: #fff;
                text-align: center;
                &:first-child {
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 4rem;
                }
            }
        }
    }
}

.mittagstisch {
    .fl-module-accordion {
        .fl-accordion {
            .fl-accordion-item {
                .fl-accordion-button-label {
                    font-weight: 700;
                    font-size: 2.2rem;
                    color: $color-primary;
                    text-transform: uppercase;
                }
                .fl-accordion-button-icon {
                    opacity: 1;
                    color: $color-primary;
                }
            }
        }
    }
    .fl-module-rich-text {
        .fl-rich-text {
            text-align: center;
        }
    }
}

.fl-module-content-slider {
    .fl-content-slider {
        .fl-content-slider-wrapper {
            .fl-slide {
                .fl-slide-foreground {
                    background: #fff;
                    border: 1px solid $gray-lighter;
                    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
                    margin: 0 2em;
                    .fl-slide-content-wrap {
                        width: 75%;
                        .fl-slide-content {
                            margin: 60px 0;
                            @media (max-width: 767px) {
                                background-color: transparent !important;
                            }
                            .fl-slide-title {
                                color: $gray-dark;
                            }
                            .fl-slide-text {
                                p {
                                    color: $gray-dark;
                                }
                            }
                        }
                    }
                }
            }
        }
        .bx-viewport {
            min-height: 445px !important;
        }
        .bx-controls {
            .bx-pager {
                .bx-pager-item {
                    .bx-pager-link {
                        background: $gray-light;
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        &.active {
                            background: $gray;
                        }
                    }
                }
            }
        }
    }
}

.fl-module-photo-gallery {
    .uabb-photo-gallery-item {
        padding: 5px !important;
    }
}