#cookie-notice {
    background: $color-secondary !important;

    .cookie-notice-container {
        font-size: $font-size-small;

        @media screen and (min-width: $screen-sm-min) {
            font-size: $font-size-base;
        }

        #cn-notice-text {
            vertical-align: middle;
        }

        a {
            @extend .cta-btn;
            display: block;
            margin: .5em auto 0;
            max-width: 225px;
            padding: .25em .75em;

            @media screen and (min-width: $screen-is-min) {
                display: inline-block;
                margin: 0 .5em;
            }
        }
    }
}
