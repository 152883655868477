body {

}

.content-wrap,
#tinymce {
    h1,
    h2,
    h3,
    h4 {
    }

    h1{
        font-family: $font-family-serif;
        color: $color-primary;
    }

    h2 {
        font-family: $font-family-serif;
        color: $color-primary;
    }

    h3 {

    }

    h4 {

    }
}

.tagline{
    font-weight:300;
    font-size: 2rem;
}

.medium-text {
    max-width: 940px;
    text-align: center;
    margin: 0 auto;
    a {
        color: $color-tertiary;
        text-decoration: underline !important;
        &:hover {
            color: $color-tertiary;
            text-decoration: none;
        }
    }
}