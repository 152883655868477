#header {
    @extend .hidden-xs;
    @extend .hidden-is;
    position: relative;

    // First line of the website. Often contains contact information
    .header-contact {
        display: none;
        position: absolute;
        z-index: 99999;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: $font-size-small;

        @media screen and (min-width: $screen-sm) {
            display: block;
        }

        .contactInfo {
            display: flex;
            justify-content: space-between;

            .contact-btn {
                background: $color-primary;
                font-family: $font-family-serif;
                font-size: 1.8rem;
                color: #fff;
                padding: .5em 1em;
                &:hover {
                    background: darken($color-primary, 10%);
                    cursor: pointer;
                }
            }

        }

        .opening-times {
            @include make-sm-column(5);
            display: none;

            @media screen and (min-width: $screen-sm) {
                display: block;
            }
        }

        .contact-infos {
            @include make-sm-column(7);
            display: none;

            @media screen and (min-width: $screen-sm) {
                display: block;
            }

            ul {
                @extend .nav;
                @extend .nav-right;
                line-height: 1;

                li {
                    font-size: $font-size-small;

                    a,
                    i {
                        display: inline-block;
                        font-size: $font-size-small;
                        vertical-align: middle;

                        &::before {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }

        .site-logo {
            //@include make-sm-column(4);
            position: relative;
            text-align: center;

            .custom-logo-link {
                .custom-logo {
                    background: rgba(255, 255, 255, .8);
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    max-width: 300px;
                    padding: 1em 1.5em;
                }
            }
        }
    }

    // Wrapper for site logo and header widgets
    .header-content {
        display: none;
        .header-widgets {
            @include make-sm-column(2);
        }

        .header-navigation {
            @include make-sm-column(6);

            ul {
                &.stacked-icons {
                    i {
                        display: block;
                        margin: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}
