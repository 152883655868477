#desktop-nav {
    @extend .hidden-xs;
    @extend .hidden-is;
    position: relative;
    z-index: 100;
    width: 100%;
    background: #fff;
    border-top: 2px solid $gray-light;
    border-bottom: 2px solid $gray-light;

    nav {
        @extend .container;
    }

    &.affix {
        position: fixed;
        top: 0;
    }

    // Generic Menu item Styles
    li {
        a {
            padding: $nav-link-padding;
            font-weight: 600;
            color: $desktop-nav-link-color;

            &:hover {
                color: $desktop-nav-link-hover-color;
                text-decoration: none;
            }
        }

        &.pipe-separator {
            display: none;
        }

        // Second Nav Level
        .sub-menu {
            display: none;
            background: $desktop-nav-bg;
            border-right: 2px solid $gray-light;
            border-bottom: 2px solid $gray-light;
            border-left: 2px solid $gray-light;

            @media screen and (min-width: $screen-sm-min) {
                display: block;
                visibility: hidden;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 10;
                min-width: 100%;
                transition: 0.1s .15s; /* delay of 0.15 seconds on hover off */
            }

            li {
                a {
                    font-weight: 400;
                }
            }
        }
    }

    // Current Menu Item Styles
    .current-menu-item,
    .current-menu-ancestor {
        > a {
            background: $desktop-nav-link-active-bg;
            color: $desktop-nav-link-active-color;

            &:hover,
            &:active,
            &:focus {
                background: $desktop-nav-link-active-bg;
                color: $desktop-nav-link-active-color;
            }
        }
    }

    // Menu item description
    .has-description {
        .menu-item-description {
            display: inline-block;
            margin-right: .25em;
            width: 1em;
            vertical-align: middle;
            line-height: 1;

            img {
                display: inline-block;
                width: auto;
                height: auto;
                vertical-align: initial;
            }

            i {
                margin-top: -.265em;
            }
        }
    }
}




// Open First Level Sub-Menus of the last item to the left
#desktop-nav {
    nav {
        @media screen and (min-width: $screen-sm-min) {
            > ul {
                > li:last-child {
                    .sub-menu {
                        left: auto;
                        right: 0;

                        // Open Second Level Sub-Menus of the last item to the left
                        .sub-menu {
                            right: 100%;
                        }
                    }
                }
            }

            li:hover {
                > .sub-menu {
                    visibility: visible;
                    transition-delay: 0s;
                }
            }
        }
    }
}


// Third Nav Level
#desktop-nav {
    .sub-menu {
        .sub-menu {
            @media screen and (min-width: $screen-sm-min) {
                top: 0;
                left: 100%;
            }
        }
    }
}
