/* playfair-display-regular - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/playfair-display-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'),
         url('../fonts/playfair-display-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/playfair-display-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/playfair-display-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/playfair-display-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/playfair-display-v13-latin-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-700 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/playfair-display-v13-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'),
         url('../fonts/playfair-display-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/playfair-display-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/playfair-display-v13-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/playfair-display-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/playfair-display-v13-latin-700.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }